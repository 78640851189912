.request-container {
  display: flex;
  align-items: flex-start;
  height: 100%;
}

.request-navigation {
  padding: 20px;
  width: 20%;
  height: 100%;
  background-color: red;

  ul {
    padding-left: 0;

    ul {
      padding-left: 1rem;
    }
  }

  .navigation-item {
    cursor: pointer;

    &.active {
      font-weight: bold;
    }
  }
}

.request-form {
  background-color: yellow;
  width: 100%;
  padding: 20px;
}

.field-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.field-label {
  width: 20%;
}